/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.epit-container {
    padding: 10px 20px;
}

.slider {
    .swiper-pagination {
        display: inline-block;
        width: unset !important;
        margin: auto !important;
        left: calc(50%) !important;
        transform: translateX(-50%) scale(75%);
        background: #fff;
        border-radius: 10px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
    }
    .logo {
        width: 60px;
        margin: auto;
        position: relative;
        z-index: 999;
        border-radius: 100%;
        border: 2px solid #fff;
        height: 62px;
        background: #fff;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #e30616 !important;
    }
    .swiper-pagination-bullet {
        background: #5e5e5e !important;
        opacity: 1 !important;
    }
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: exo;
    src: url("/assets/fonts/Exo-VariableFont_wght.ttf");
}

body {
    font-family: exo;
}

.custom-popup {
    .modal-wrapper {
        --background: transparent;
        background: transparent !important;
    }
}

.alert.form-error {
    div {
        text-align: right;
        font-size: 12px;
        color: #e31818;
        margin-top: 3px;
    }
}

.error-box {
    width: 100%;
    margin: 10px auto;
    background: #ee4242;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 10px 0px 12px 00px;
    font-size: 11px;
    font-family: exo;
    font-weight: normal;
    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid #fff;
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid #fff;
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.success-box {
    /*
  width: 100%;
  padding: 10px 10px 18px 10px;
  margin: 10px auto;
  background: #3c7c36;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;*/
    width: 100%;
    padding: 10px 0px 12px 00px;
    margin: 10px auto;
    background: #3c7c36;
    color: #fff;
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    font-family: exo;
    font-weight: normal;
    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid #fff;
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid #fff;
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.pac-logo:after {
    display: none !important;
}

.pac-container {
    background-color: #f3f1f1;
}

.pac-item {
    padding: 5px;
}

.pointer {
    cursor: pointer;
}

.ion-toolbar {
    position: relative;
    margin-top: -25px;
    .ion-title {
        text-align: left;
        padding: 20px 20px 10px 20px;
        background: #e30616;
        color: #fff;
    }
    .large-title {
        display: block;
        font-size: 18px;
        font-weight: bold;
        ion-icon {
            font-size: 23px;
            margin-bottom: -5px;
        }
    }
}

ion-header {
    background-color: #e30b15 !important;
}

.cart-button {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    bottom: 120px;
    right: 20px;
    background: #ff001c;
    color: #fff;
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(40deg);
    border: 4px dashed #fff;
    transition: 0.5s;
    ion-icon {
        font-size: 25px !important;
        color: #ffffff !important;
    }
    span {
        font-weight: 600;
        font-size: 10px;
        display: block;
        text-align: center;
    }
}

.cart-button.cart-top {
    bottom: calc(100% - 130px);
    transform: rotate(0deg);
}

.cart-button.animating {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    top: unset;
    bottom: calc(50% - 25px) !important;
    right: calc(50% - 25px) !important;
    background: #ff001c;
    color: #fff;
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(0deg);
    border: none;
    &::before {
        content: " ";
        width: 75px;
        height: 75px;
        display: block;
        position: absolute;
        left: -3px;
        top: -3px;
        border-radius: 100%;
        border: 3px dashed #fff;
        animation: cart-animation-1 1.5s infinite linear;
    }
    ion-icon {
        opacity: 1;
        animation: cart-animation-2 1.5s linear;
    }
    span {
        font-weight: 600;
        font-size: 12px;
        display: block;
        text-align: center;
    }
}

.red-button {
    background: #e30616;
    padding: 15px 20px;
    border-radius: 5px;
    color: #fff;
}

.text-center {
    text-align: center;
}

.red-color {
    color: #e74c3c;
}

green-color {
    color: #27ae60;
}

.ion-back-opacity {
    opacity: 0.5;
}

@keyframes cart-animation-1 {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(90deg) scale(1);
    }
    100% {
        transform: rotate(180deg) scale(1);
    }
}

@keyframes cart-animation-2 {
    0% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }
    49.9% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }
    50% {
        transform: translateY(-100px) scale(1);
        opacity: 0;
    }
    75% {
        padding-bottom: 0;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    80% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }
    85% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    90% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }
    95% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    100% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

.submit-buttons {
    background: #e8081db5;
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: #f9f9f9;
    box-shadow: 0 1px 4px 1px #b3b3b3;
    font-weight: bold;
    font-size: 12px;
    ion-icon {
        font-size: 20px;
        margin-bottom: -5px;
        margin-right: 10px;
    }
    .text {
        font-size: 12px !important;
        text-transform: full-size-kana;
    }
}

.submit-buttons:disabled {
    background: #b3b3b3b5;
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: #646464;
    box-shadow: 0 0px 0px 0px #b3b3b3;
    font-weight: bold;
    font-size: 12px;
}

.sub-items {
    font-size: 13px;
    color: #79797996;
    padding-left: 5px;
    font-family: poppins;
    line-height: 15px;
    ul {
        padding-left: 5px !important;
        li {
            list-style: none;
        }
    }
}

.pb100 {
    padding-bottom: 100px;
}

// padding left 0
.pl-0 {
    padding-left: 0px !important;
}

.height-auto {
    height: auto !important;
}

.text-red {
    color: red !important;
}

.find-nearby-back-button {
    top: 7px !important;
    position: absolute !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.checkout-select {
    .alert-head {
        display: block;
        width: 100%;
        position: relative;
        background: #e30d0d;
        font-weight: 600;
        padding: 5px;
        h2 {
            font-size: 17px;
            color: #fff;
            margin: 0 !important;
            padding: 5px 0;
        }
        .alert-wrapper.sc-ion-alert-ios {
            border-radius: 5px;
        }
    }
    .alert-wrapper.sc-ion-alert-ios {
        border-radius: 5px;
    }
    .alert-message.sc-ion-alert-ios {
        padding-bottom: 0 !important;
    }
    .alert-button.sc-ion-alert-md {
        border-radius: 2px;
        position: relative;
        background-color: transparent;
        color: #f93b5e;
        font-weight: 500;
        text-align: end;
        text-transform: uppercase;
        overflow: hidden;
    }
    .alert-radio-label.sc-ion-alert-ios {
        color: #7b7b7b;
    }
    .alert-button.sc-ion-alert-ios {
        color: #ea4646;
        font-size: 12px;
    }
    [aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
        border-color: #ea4646;
    }
    [aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
        color: #ea4646;
        font-weight: bold;
    }
}

.m-0 {
    margin: 0px !important;
}

.float-right {
    float: right !important;
}

.web-footer {
    background-color: #403d3d;
    padding: 20px 0;
    .footer-left {
        text-align: left;
        ul {
            list-style: none;
            display: inline-block;
            margin: 0;
            li {
                display: inline;
                a {
                    color: #ffffff;
                    text-decoration: none;
                    margin: 5px;
                }
            }
        }
    }
    .footer-right {
        color: #ffffff;
        text-align: right;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.not-found {
    width: 80%;
    text-align: center;
    margin-top: 40%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 20px;
    color: #9a9494;
    ion-icon {
        font-size: 40px;
    }
}

.page-bottom-padding-fix {
    padding-bottom: 200px;
}

.text-right {
    text-align: right;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;
    &.green-dot {
        background-color: #27ae60;
    }
    &.orange-dot {
        background-color: #dfd934;
    }
    &.red-dot {
        background-color: #da2f11;
    }
}

.modal-wrapper {
    border-radius: 10px !important;
}

.remove-position-absolute {
    position: relative !important;
}




.ion-spinner-red {
    color: #e30616 !important;
}

.ion-spinner-white {
    color: #ffffff !important;
}

ion-select {
    .select-icon-inner {
        left: -5px !important;
        margin-top: -7px !important;
        border-top: 9px solid !important;
        border-right: 6px solid transparent !important;
        border-left: 6px solid transparent !important;
    }
}

.main-container-for-dishes {
    p {
        margin: 5px;
        margin-left: 10px;
        font-size: 14px;
        color: #828282;
        position: relative;
        padding-left: 10px;
        &::after {
            content: " ";
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            top: 4px;
            left: -3px;
            transform: translateY(-50%);
            background: #ececec;
            border-radius: 100%;
        }
    }
}

.action-sheet-button-inner.sc-ion-action-sheet-md {
    font-size: 14px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

ion-icon:focus-visible{
    outline: none !important;
}

//important this is shit

.cart-popup-for-tabs{
    --background: transparent !important; 
    --box-shadow: none !important;
}

.cart-popup-for-tabs ion-content::part(scroll) {
    overflow-y: hidden !important;
  }

ion-header{
    border-top: 27px #e30616 solid !important;
}

.ion-toolbar {
    position: relative;
    margin-top: -25px;
    .ion-title {
      text-align: left;
      padding: 35px 20px 10px 20px !important;
      background: #e30616;
      color: #fff;
    }
    .small-title {
      font-size: 12px;
      display: block;
    }
    .large-title {
      display: block;
      font-size: 25px;
      font-weight: bold;
    }
  }